<template>
  <!-- MemberLayout -->
  <div class="flex flex-col min-w-[128rem] max-w-[136rem] min-h-[100vh] mx-auto">
    <gnb />
    <div class="flex-1 pt-40 px-24 pb-[12rem] pt-[6.8rem]">
      <slot></slot>
    </div>
  </div>
</template>
<script setup lang="ts">
import { useHead } from '@unhead/vue';

import Gnb from '@/components/app/gnb-header.vue';
import { BODY_CLASS } from '@/constants/common.const';

useHead({
  bodyAttrs: {
    class: BODY_CLASS
  }
});
</script>
<style lang="scss" src="~~/assets/css/main.scss" />
